import * as React from "react";
import BanniereEvenement from "./BanniereEvenementAvril"
import DetailsEvenements from "./DetailsevenementsAvril"



function RencontreSpirituelleFeminine() {
  return (
    <main>
    <BanniereEvenement/>
    <DetailsEvenements/>
    </main>
  );
}

export default RencontreSpirituelleFeminine;