import Flyer1 from "../../img/Flyer1.webp"
import Flyer2 from "../../img/Flyer2.webp"
import Flyer3 from "../../img/Flyer3.webp"
import Flyer4 from "../../img/1611.webp"
import Flyer5 from "../../img/Art-spiritualite.webp"
import Flyer6 from "../../img/Flyer6.webp"
import Flyer7 from "../../img/Flyer7.webp"
import Flyer8 from "../../img/Flyer8.webp"
import Flyer9 from "../../img/22_03_25.webp"
import Flyer10 from "../../img/29_03_25.webp"
import Flyer11 from "../../img/Flyer11.webp"
import Flyer12 from "../../img/Flyer12.webp"

const eventsData = [
    {
      id: 1,
      title: "Événement 1",
      img: Flyer4,
      date: "2024-11-16",
      href: "/",
    },
    {
      id: 2,
      title: "Événement 2",
      img: Flyer3,
      date: "2024-11-15",
      href: "/event2",
    },
    {
      id: 3,
      title: "Événement 3",
      img: Flyer1,
      date: "2024-11-10",
      href: "/event3",
    },
    {
        id: 4,
        title: "Événement 4",
        img: Flyer2,
        date: "2024-11-10",
        href: "/",
      },
      {
        id: 5,
        title: "Événement 5",
        img: Flyer5,
        date: "2024-12-14",
        href: "/",
      },
      {
        id: 6,
        title: "Événement 6",
        img: Flyer6,
        date: "2025-01-25",
        href: "/",
      },
      {
        id: 7,
        title: "Événement 7",
        img: Flyer7,
        date: "2025-03-01",
        href: "#",
      },
      {
        id: 7,
        title: "Événement 8",
        img: Flyer8,
        date: "2025-02-22",
        href: "#",
      },
      {
        id: 8,
        title: "Événement 9",
        img: Flyer9,
        date: "2025-03-22",
        href: "#",
      },
      {
        id: 10,
        title: "Événement 10",
        img: Flyer10,
        date: "2025-03-29",
        href: "/ArtEtSpiritualiteMars",
      },
      {
        id: 11,
        title: "Événement 11",
        img: Flyer11,
        date: "2025-04-13",
        href: "/RencontreSpirituelleFeminine",
      },
      {
        id: 12,
        title: "Événement 12",
        img: Flyer12,
        date: "2025-04-12",
        href: "/pageEvenementIntroductionAlaMeditationSoufie",
      },
  ];
  
  export default eventsData;
  