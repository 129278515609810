import * as React from "react";
import BanniereEvenement from "./BanniereEvenementMars"
import DetailsEvenements from "./DetailsevenementsMars"
import FormWidget from "./FormWidgetMars";



function ArtEtSpiritualiteMars() {
  return (
    <main>
    <BanniereEvenement/>
    <DetailsEvenements/>
    <FormWidget/>
    </main>
  );
}

export default ArtEtSpiritualiteMars;